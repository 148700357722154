import React, { useEffect, useState, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import male from './male.png'
import female from './female.png'
import './App.css'
import axios from 'axios'
import queryString from 'query-string'

const Chat = () => {
  const { uid, gid } = queryString.parse(window.location.search)
  const fileInputRef = useRef(null)
  const chatContainerRef = useRef(null)
  const replayRef = useRef(null)
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [show, setShow] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)
  const [totalMessages, setTotalMessages] = useState(0)
  const [replay, setReplay] = useState({})
  const [active, setActive] = useState(false)
  const [user, setUser] = useState(70)
  const [club, setClub] = useState({})
  const [lastMessageId, setLastMessageId] = useState(0)
  const [nextPage, setNextPage] = useState()
  const [moreMessages, setMoreMessages] = useState([])
  useEffect(() => {
    getMessages()
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const removeImage = () => {
    setSelectedFile(null)
    setPreviewImage(null)
  }

  const handleOutsideClick = (event) => {
    if (
      chatContainerRef.current &&
      !chatContainerRef.current.contains(event.target)
    ) {
      setShow(0)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }
  var getNewMessages = async (lid, smessages) => {
    if (lid > 0) {
      const resp = await axios.get(
        'https://ln.business/chat-api.php?action=get-new-messages&club=' +
          atob(gid) +
          '&lastmid=' +
          lid,
      )
      setMessages([...smessages])
      if (resp.data.success == true) {
        setMessages([...smessages, ...resp.data.messages])
        setLastMessageId(resp.data.messages[resp.data.messages.length - 1].id)
      }
    }
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0]

    if (file) {
      setSelectedFile(file)
      // Display preview image
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage(reader.result)
      }
      reader.readAsDataURL(file)
    } else {
      setSelectedFile(null)
      setPreviewImage(null)
    }
  }

  const dateFormat = (date) => {
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    return formattedDate
  }

  const sendMessage = async () => {
    if (selectedFile || newMessage) {
      const formData = new FormData()
      if (selectedFile) {
        formData.append('file', selectedFile)
      }
      formData.append('replay_to', replay.id ? replay.id : 0)
      formData.append('user', user)
      formData.append('message', newMessage)
      formData.append('club', club.id)
      formData.append('action', 'create-message')
      const resp = await axios.post(
        'https://ln.business/chat-api.php',
        formData,
      )
      if (resp.data.success == true) {
        setRefresh(!refresh)
        setNewMessage('')
        setSelectedFile(null)
        setPreviewImage(null)
        setReplay({})
        scrollToBottom()
      }
    } else alert('Enter Message')
  }
  const toogleReply = (id) => {
    show ? setShow('') : setShow(id)
  }

  const getMessages = async () => {
    setUser(atob(uid))
    const resp1 = await axios.get(
      'https://ln.business/chat-api.php?action=get-club&club=' + atob(gid),
    )
    const resp = await axios.get(
      'https://ln.business/chat-api.php?action=get-messages&club=' + atob(gid),
    )
    setMessages(resp.data.messages)
    setLastMessageId(resp.data.messages[resp.data.messages.length - 1].id)
    setTotalMessages(resp.data.msg_count)
    setClub(resp1.data.data)
    setNextPage(resp.data.nextPage)
    scrollToBottom()
    console.log('referrer', document.referrer)
    setActive(true)
    const intervalId = setInterval(() => {
      getNewMessages(
        resp.data.messages[resp.data.messages.length - 1].id,
        resp.data.messages,
      )
    }, 3000)
    return () => clearInterval(intervalId)
  }

  const getMoreMessages = async () => {
    setNextPage(null)
    setActive(false)
    const resp = await axios.get(
      'https://ln.business/chat-api.php?action=get-messages&club=' +
        atob(gid) +
        '&page=' +
        nextPage,
    )
    //setMessages([...resp.data.messages, ...messages])
    setMoreMessages([...resp.data.messages, ...moreMessages])
    setNextPage(resp.data.nextPage)
    setActive(true)
  }
  return (
    <div className="rel_msg_container">
      {active ? (
        <div className="container-fluid h-100">
          <div className="row justify-content-center h-100">
            <div className="col-md-12 col-xl-12 chat">
              <div className="card">
                <div className="card-header msg_head">
                  <div className="d-flex bd-highlight">
                    <div className="img_cont">
                      <img
                        src={
                          club.upload_image
                            ? 'https://ln.business/' + club.upload_image
                            : 'https://ln.business/img/logo-lnb.png'
                        }
                        className="rounded-circle user_img"
                      />
                      <span className="online_icon"></span>
                    </div>
                    <div className="user_info">
                      <span>{club.title}</span>
                      <p>{totalMessages} Messages</p>
                    </div>
                    {/* <div className="video_cam">
                      <span>
                        <i className="fas fa-video"></i>
                      </span>
                      <span>
                        <i className="fas fa-phone"></i>
                      </span>
                    </div> */}
                  </div>
                  {/* <span id="action_menu_btn">
                    <i
                      className="fas fa-ellipsis-v"
                      onClick={() => setShow(!show)}
                    ></i>
                  </span> */}
                  <a href="https://ln.business/">
                    <i
                      className="fa fa-close"
                      style={{
                        position: 'absolute',
                        right: 15,
                        top: 15,
                        color: '#c00',
                        fontSize: 30,
                      }}
                    ></i>
                  </a>
                </div>
                <div className="card-body msg_card_body" ref={chatContainerRef}>
                  {nextPage && (
                    <div
                      onClick={() => getMoreMessages()}
                      className="load_more_messages"
                    >
                      Load More...
                    </div>
                  )}
                  {moreMessages &&
                    moreMessages.map((message, i) => (
                      <>
                        {message.user_id == user ? (
                          <div
                            key={i}
                            className="d-flex justify-content-end mb-4"
                          >
                            <div className="msg_cotainer_send">
                              {message.replay_img ? (
                                <div className="replayImg">
                                  <img
                                    className="send_pic"
                                    src={
                                      'https://ln.business/' +
                                      message.replay_img
                                    }
                                  />{' '}
                                  <i className="fas fa-reply"></i>
                                </div>
                              ) : message.replay_msg ? (
                                <div className="replay_msg">
                                  {message.replay_msg}{' '}
                                  <i className="fas fa-reply"></i>
                                </div>
                              ) : null}
                              {message.image_path && (
                                <img
                                  className="send_pic"
                                  src={
                                    'https://ln.business/' + message.image_path
                                  }
                                />
                              )}
                              <div>{message.message}</div>
                              <span className="msg_time_send">
                                {' '}
                                {dateFormat(new Date(message.date_time))}
                              </span>
                            </div>
                            <div className="img_cont_msg">
                              <img
                                src={
                                  message.profile_pic
                                    ? 'https://ln.business/' +
                                      message.profile_pic
                                    : message.Gender == 'Male'
                                    ? male
                                    : female
                                }
                                className="rounded-circle user_img_msg"
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              key={i}
                              className="d-flex justify-content-start mb-4"
                            >
                              <div className="img_cont_msg">
                                <img
                                  src={
                                    message.profile_pic
                                      ? 'https://ln.business/' +
                                        message.profile_pic
                                      : message.Gender == 'Male'
                                      ? male
                                      : female
                                  }
                                  className="rounded-circle user_img_msg"
                                />
                              </div>
                              <div>
                                <a
                                  className="u_name"
                                  href={
                                    'https://ln.business/bintro/' +
                                    message.user_id +
                                    '/'
                                  }
                                >
                                  {message.fullName}
                                </a>
                                <div className="msg_cotainer">
                                  {message.replay_img ? (
                                    <div className="replayImg">
                                      <img
                                        src={
                                          'https://ln.business/' +
                                          message.replay_img
                                        }
                                      />{' '}
                                      <i className="fas fa-reply"></i>
                                    </div>
                                  ) : message.replay_msg ? (
                                    <div className="replay_msg">
                                      {message.replay_msg}{' '}
                                      <i className="fas fa-reply"></i>
                                    </div>
                                  ) : null}
                                  {message.image_path && (
                                    <img
                                      className="send_pic"
                                      src={
                                        'https://ln.business/' +
                                        message.image_path
                                      }
                                    />
                                  )}
                                  <div>{message.message}</div>
                                  <span className="msg_time">
                                    {dateFormat(new Date(message.date_time))}
                                  </span>
                                </div>
                              </div>
                              <span id="action_menu_btn">
                                <i
                                  className="fas fa-ellipsis-v"
                                  onClick={() => toogleReply(message.id)}
                                ></i>
                              </span>
                              <div
                                style={{ position: 'relative', width: '100%' }}
                              >
                                {show == message.id && (
                                  <div className="action_menu">
                                    <ul>
                                      <li onClick={() => setReplay(message)}>
                                        <i className="fas fa-reply"></i> Replay
                                      </li>
                                      {/* <li>
                                      <i className="fas fa-users"></i> Add to
                                      close friends
                                    </li>
                                    <li>
                                      <i className="fas fa-plus"></i> Add to
                                      group
                                    </li>
                                    <li>
                                      <i className="fas fa-ban"></i> Block
                                    </li> */}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ))}
                  {messages.map((message, i) => (
                    <>
                      {message.user_id == user ? (
                        <div
                          key={i}
                          className="d-flex justify-content-end mb-4"
                        >
                          <div className="msg_cotainer_send">
                            {message.replay_img ? (
                              <div className="replayImg">
                                <img
                                  className="send_pic"
                                  src={
                                    'https://ln.business/' + message.replay_img
                                  }
                                />{' '}
                                <i className="fas fa-reply"></i>
                              </div>
                            ) : message.replay_msg ? (
                              <div className="replay_msg">
                                {message.replay_msg}{' '}
                                <i className="fas fa-reply"></i>
                              </div>
                            ) : null}
                            {message.image_path && (
                              <img
                                className="send_pic"
                                src={
                                  'https://ln.business/' + message.image_path
                                }
                              />
                            )}
                            <div>{message.message}</div>
                            <span className="msg_time_send">
                              {' '}
                              {dateFormat(new Date(message.date_time))}
                            </span>
                          </div>
                          <div className="img_cont_msg">
                            <img
                              src={
                                message.profile_pic
                                  ? 'https://ln.business/' + message.profile_pic
                                  : message.Gender == 'Male'
                                  ? male
                                  : female
                              }
                              className="rounded-circle user_img_msg"
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            key={i}
                            className="d-flex justify-content-start mb-4"
                          >
                            <div className="img_cont_msg">
                              <img
                                src={
                                  message.profile_pic
                                    ? 'https://ln.business/' +
                                      message.profile_pic
                                    : message.Gender == 'Male'
                                    ? male
                                    : female
                                }
                                className="rounded-circle user_img_msg"
                              />
                            </div>
                            <div>
                              <a
                                className="u_name"
                                href={
                                  'https://ln.business/bintro/' +
                                  message.user_id +
                                  '/'
                                }
                              >
                                {message.fullName}
                              </a>
                              <div className="msg_cotainer">
                                {message.replay_img ? (
                                  <div className="replayImg">
                                    <img
                                      src={
                                        'https://ln.business/' +
                                        message.replay_img
                                      }
                                    />{' '}
                                    <i className="fas fa-reply"></i>
                                  </div>
                                ) : message.replay_msg ? (
                                  <div className="replay_msg">
                                    {message.replay_msg}{' '}
                                    <i className="fas fa-reply"></i>
                                  </div>
                                ) : null}
                                {message.image_path && (
                                  <img
                                    className="send_pic"
                                    src={
                                      'https://ln.business/' +
                                      message.image_path
                                    }
                                  />
                                )}
                                <div>{message.message}</div>
                                <span className="msg_time">
                                  {dateFormat(new Date(message.date_time))}
                                </span>
                              </div>
                            </div>
                            <span id="action_menu_btn">
                              <i
                                className="fas fa-ellipsis-v"
                                onClick={() => toogleReply(message.id)}
                              ></i>
                            </span>
                            <div
                              style={{ position: 'relative', width: '100%' }}
                            >
                              {show == message.id && (
                                <div className="action_menu">
                                  <ul>
                                    <li onClick={() => setReplay(message)}>
                                      <i className="fas fa-reply"></i> Replay
                                    </li>
                                    {/* <li>
                                      <i className="fas fa-users"></i> Add to
                                      close friends
                                    </li>
                                    <li>
                                      <i className="fas fa-plus"></i> Add to
                                      group
                                    </li>
                                    <li>
                                      <i className="fas fa-ban"></i> Block
                                    </li> */}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ))}
                  {/* <div className="d-flex justify-content-start mb-4">
                <div className="img_cont_msg">
                  <img
                    src="https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg"
                    className="rounded-circle user_img_msg"
                  />
                </div>
                <div className="msg_cotainer">
                  Hi, how are you samim?
                  <span className="msg_time">8:40 AM, Today</span>
                </div>
              </div>
              <div className="d-flex justify-content-end mb-4">
                <div className="msg_cotainer_send">
                  Hi Khalid i am good tnx how about you?
                  <span className="msg_time_send">8:55 AM, Today</span>
                </div>
                <div className="img_cont_msg">
                  <img
                    src="https://avatars.hsoubcdn.com/ed57f9e6329993084a436b89498b6088?s=256"
                    className="rounded-circle user_img_msg"
                  />
                </div>
              </div> */}
                </div>
                <div className="card-footer">
                  {previewImage && (
                    <div className="selectedImg">
                      <img style={{ width: '100%' }} src={previewImage} />
                      <i
                        className="fa fa-close closeImg"
                        role="button"
                        onClick={() => removeImage()}
                      ></i>
                    </div>
                  )}
                  {replay.id && (
                    <div className="replayMsg">
                      {replay.image_path ? (
                        <img
                          className="send_pic"
                          src={'https://ln.business/' + replay.image_path}
                        />
                      ) : (
                        replay.message
                      )}
                      <i
                        class="fa fa-times closeReplay"
                        onClick={() => setReplay({})}
                      ></i>
                    </div>
                  )}
                  <div className="input-group">
                    <div className="input-group-append">
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />

                      <span
                        className="input-group-text attach_btn"
                        onClick={handleButtonClick}
                      >
                        <i className="fas fa-paperclip"></i>
                      </span>
                    </div>
                    <textarea
                      name=""
                      className="form-control type_msg"
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder="Type your message..."
                      value={newMessage}
                    ></textarea>
                    <div
                      className="input-group-append"
                      onClick={() => sendMessage()}
                    >
                      <span className="input-group-text send_btn">
                        <i className="fas fa-location-arrow"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="spinner-box">
          <div className="configure-border-1">
            <div className="configure-core"></div>
          </div>
          <div className="configure-border-2">
            <div className="configure-core"></div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Chat
